<template>
  <el-container class="home-container">
    <div class="c-main">
      <div class="c-header">
        <Header></Header>
        <div class="m-main">
          <el-aside>
            <el-menu :default-active="this.$router.path" router active-text-color="#606266">
              <!--  人才库-->
              <el-submenu index="1" v-if="!activeTitle">
                <template slot="title">
                  <i class="fa fa-users"></i>
                  <span>人才库</span>
                </template>
                <el-menu-item index="/pc/talentPool">
                  <span slot="title">人才库</span>
                </el-menu-item>
              </el-submenu>
              <el-submenu index="2" v-if="!activeTitle">
                <template slot="title">
                  <i class="fa fa-location-arrow"></i>
                  <span>收藏管理</span>
                </template>
                <el-menu-item index="/pc/collection">
                  <span slot="title">职位收藏</span>
                </el-menu-item>
                <el-menu-item index="/pc/collect">
                  <span slot="title">简历收藏</span>
                </el-menu-item>
              </el-submenu>
              <!--  简历管理-->
              <el-submenu index="3" v-if="!activeTitle">
                <template slot="title">
                  <i class="fa fa-address-book"></i>
                  <span>订单管理</span>
                </template>
                <el-menu-item index="/pc/resume">
                  <span slot="title">已购简历</span>
                </el-menu-item>                
              </el-submenu>
              <!-- 职业管理 -->
              <el-submenu index="4" v-if="!activeTitle">
                <template slot="title">
                  <i class="fa fa-location-arrow"></i>
                  <span>职位管理</span>
                </template>
                <el-menu-item index="/pc/occupation">
                  <span slot="title">职位发布</span>
                </el-menu-item>
              </el-submenu>
              <!-- 订单管理 -->
              <!-- <el-submenu index="4">
              <template slot="title">
                <i class="fa fa-th-list"></i>
                <span>钱包</span>
              </template>
            </el-submenu> -->

              <!-- 个人中心 -->
              <el-submenu index="5">
                <template slot="title">
                  <i class="fa fa-cog"></i>
                  <span>公司认证</span>
                </template>
                <el-menu-item index="/pc/company">
                  <span slot="title">提交资料</span>
                </el-menu-item>
              </el-submenu>

              <!-- <el-submenu index="5" v-if="getLoginStatus==1 && activeTitle">
                <template slot="title">
                  <i class="fa fa-handshake-o"></i>
                  <span>经营网点</span>
                </template>
                <el-menu-item index="/pc/franchise">
                  <span slot="title">网点资质申请</span>
                </el-menu-item>
                <el-menu-item index="/pc/franchise/import">
                  <span slot="title">数据导入</span>
                </el-menu-item>
                <el-menu-item index="/pc/franchise/list">
                  <span slot="title">数据列表</span>
                </el-menu-item>
              </el-submenu> -->
              <!-- <el-submenu index="6" v-if="getLoginStatus==1 && activeTitle">
                <template slot="title">
                  <i class="fa fa-money"></i>
                  <span>佣金管理</span>
                </template>
                <el-menu-item index="/pc/commission/index">
                  <span slot="title">佣金管理</span>
                </el-menu-item>
              </el-submenu> -->
              <!--  财务-->
              <el-submenu index="6" v-if="!activeTitle">
                <template slot="title">
                  <i class="fa fa-credit-card-alt"></i>
                  <span>个人中心</span>
                </template>
                <el-menu-item index="/pc/myBalance">
                  <span slot="title">我的余额</span>
                </el-menu-item>
                <!-- <el-menu-item index="/pc/recharge">
                  <span slot="title">充值中心</span>
                </el-menu-item>
                <el-menu-item index="/pc/invoice/apply">
                  <span slot="title">开票申请</span>
                </el-menu-item>
                <el-menu-item index="/pc/invoice/record">
                  <span slot="title">开票记录</span>
                </el-menu-item>
                <el-menu-item index="/pc/invoice/info">
                  <span slot="title">开票信息</span>
                </el-menu-item> -->
              </el-submenu>
              <!-- 设置 -->
              <!-- <el-submenu index="8">
              <template slot="title">
                <i class="fa fa-cog"></i>
                <span>设置</span>
              </template>
              <el-menu-item index="/pc/account">
                <span slot="title">账号管理</span>
              </el-menu-item>
            </el-submenu> -->
            </el-menu>
          </el-aside>
          <div class="m-container">
            <!-- <el-row v-if="getLoginStatus==1">
              <el-col :span="12" class="l-line" :class="{active:activeTitle}" @click.native="changeResource(1)">人力资源输入
              </el-col>
              <el-col :span="12" :class="{active:!activeTitle}" @click.native="changeResource(0)">动用人力资源</el-col>
            </el-row> -->
            <el-main>
              <!-- 需要缓存的组件 -->
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
              <!-- 不需要缓存的组件 -->
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </el-main>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="el-footer">
        <div class="footer">
          <div class="f-main">
            <div class="m-ul">
              <div class="m-item">
                <router-link to="/pc/home">首页</router-link>
              </div>
              <div class="m-item">
                <a href="/home/about?id=38">十二方产业</a>
              </div>
              <!-- <div class="m-item">
                <a href="/home/admin_login">管理者登录</a>
              </div> -->
            </div>
            <div class="m-txt">
              <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021002198号-1</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 右侧固定区域 -->
    <div class="floatWindow">
      <div class="list">
        <div class="item" @mouseleave="wxCodeHide">
          <div @mouseenter="wxCode">
            <div class="school icon-lianxikefu"></div>
            <div>联系客服</div>
          </div>
          <div class="itemCon" v-if="isCode">
            <div class="ewm">
              <div class="pictrue">
                <!-- <div class="arrow"></div> -->
                <img src="../../assets/images/kefu.jpg" class="bicode" />
              </div>
              <div class="tip">扫码关注企业微信</div>
            </div>
          </div>
        </div>
        <div class="item" @click="goTop">
          <div class="school icon-icon-test"></div>
          <div>回到顶部</div>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
  import Header from "@/components/Header"
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: "Home",
    components: {
      Header
    },
    data() {
      return {
        isShow: true,
        isCode: false,
        activeTitle: false,
      }
    },
    computed: {
      ...mapGetters(['getLoginStatus'])
    },
    methods: {
      //回到顶部
      goTop() {
        (function n() {
          var t = document.documentElement.scrollTop || document.body.scrollTop;
          if (t > 0) {
            window.requestAnimationFrame(n);
            window.scrollTo(0, t - t / 5)
          }
        })()
      },
      wxCode() {
        this.isCode = true
      },
      wxCodeHide() {
        this.isCode = false
      },
      changeResource(val) {
        this.activeTitle = !this.activeTitle
        if (val == 1) {
          this.$router.push('/pc/franchise/import')
        } else {
          this.$router.push('/pc/talentPool')
        }
      }
    },


  }

</script>

<style lang="less" scoped>
  a:hover {
    color: @primaryColor;
  }

  .home-container .el-aside .el-menu .el-submenu i{
    width: 25px;
  }

  /deep/.el-submenu__title:hover,
  .el-submenu__title:focus {
    background: #eee !important;
  }

  .el-menu-item:hover,
  .el-menu-item:focus {
    background: #eee;
  }

  .el-aside::-webkit-scrollbar {
    display: none;
  }

  .el-menu--collapse .el-submenu__title span {
    display: none;
  }

  .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }

  .home-container {
    background: #fafafa;

    .c-main {
      width: 100%;
      height: 100%;
    }

    .c-header {
      width: 1200px;
      margin: 0px auto;
    }

    .el-footer {
      background: #f2f2f2;
      border-top: 2px solid #efefef;

      .footer {
        width: 1200px;
        margin: 0 auto;
        padding: 20px 0px;

        // .f-header {
        //   height: 104px;
        //   border-bottom: 1px solid #e2e2e2;
        //   display: flex;
        //   justify-content: space-between;

        //   .f-item {
        //     width: 25%;
        //     height: 104px;
        //     display: flex;
        //     align-items: center;

        //     .picture {
        //       width: 40px;
        //       height: 40px;
        //       border: 1px solid #707070;
        //       border-radius: 50%;
        //       margin-right: 10px;
        //       line-height: 40px;
        //       text-align: center;
        //     }

        //     .txt {
        //       font-size: 13px;
        //       color: #282828;
        //     }
        //   }
        // }

        .f-main {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;

          .m-ul {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .m-item {
              height: 20px;
              box-sizing: border-box;
              line-height: 20px;
              border-right: 1px solid #ddd;
              text-align: center;
              padding: 0px 10px;
            }

            .m-item:last-child {
              border-right: none;
            }
          }

          .m-txt {
            text-align: center;

            span {
              font-size: 14px;
              margin-right: 10px;
              color: #282828;
            }

            a {
              font-size: 13px;
            }
          }
        }
      }
    }

    .m-main {
      display: flex;
    }

    .el-aside {
      color: #333;
      text-align: center;
      width: 200px !important;

      .el-menu {
        border-right: none;
        font-size: 14px;
        font-weight: 600;

        .el-submenu {
          text-align: left;
          border-bottom: 1px solid #ddd;

          i {
            margin-right: 20px;
          }
        }

        .el-menu-item {
          border-top: 1px solid #ddd;

          span {
            padding-left: 35px;
          }
        }
      }
    }
  }

  .m-container {
    min-height: 860px;

    .el-row {
      padding-left: 20px;

      .el-col {
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-bottom: 2px solid #ebebeb;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
      }

      .l-line {
        border-right: 2px solid #ebebeb;
      }

      .active {
        border-bottom: 2px solid @primaryColor;
        color: @primaryColor;
      }
    }

    .el-main {
      padding: 0;
      margin: 0;
      width: 1000px;
      background: #fafafa;
      padding: 20px 0px 20px 20px;
    }
  }

  .floatWindow {
    position: fixed;
    right: 0;
    bottom: 50%;
    width: 60px;
    z-index: 99;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);

    .list {
      .item {
        position: relative;
        width: 100%;
        height: 60px;
        text-align: center;
        font-size: 12px;
        color: #5c5c5c;
        padding: 12px 0;

        &:hover {
          color: #e93323;
        }

        .school {
          margin-bottom: 10px;
          font-size: 25px;
        }

        &~.item {
          &:before {
            position: absolute;
            content: " ";
            width: 48px;
            height: 1px;
            background-color: #f0f0f0;
            top: 0;
            left: 50%;
            margin-left: -24px;
          }
        }

        .itemCon {
          right: 100%;
          position: absolute;
          top: 0;
          padding-right: 10px;

          .ewm {
            width: 140px;
            border: 1px solid #eeeeee;
            background-color: #fff;
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .tip {
              font-size: 14px;
              color: #666;
              margin-top: 5px;
            }

            .pictrue {
              width: 126px;
              height: 126px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

</style>
