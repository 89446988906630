import request from '@/libs/request'

/**
 * 总费用数据初始化
 */
export function getCompanyAmountApi() {
    return request({
        url: '/user/owner/info',
        method: 'get',
    })
}

/**
 * 账单明细数据初始化
 */
export function getBillDetailsListApi(data) {
    return request({
        url: `/user/stream/owner_score?page=${data.page}&limit=${data.limit}`,
        method: 'get',
    })
}

/**
 * 支出明细明细数据初始化
 */
export function getExpendDetailsListApi(page, limit) {
    return request({
        url: `/company/expenditure?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

/**
 * 充值记录数据初始化
 */
export function getRechargeRecodListApi(page, limit) {
    return request({
        url: `/company/recharge?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

/**
 * 生成订单号
 */
export function createCompanyOrderApi(data) {
    return request({
        url: '/company/order',
        method: 'post',
        data
    })
}

/**
 * 调起wx支付
 */
export function wxPay() {
    return request({
        url: `/company/wxPay`,
        method: 'get',
    })
}

/**
 * 调起支付宝支付
 */
export function aliPay(id) {
    return request({
        url: `/company/aliPay/${id}`,
        method: 'get',
    })
}

/**
 * 兑换积分（可用余额）
 */
export function exchangeResidueApi(data) {
    return request({
        url: '/company/exchange',
        method: 'post',
        data
    })
}

/**
 * 兑换积分（盈利积分）
 */
export function exchangeWithdrawableApi(data) {
    return request({
        url: '/company/profit',
        method: 'post',
        data
    })
}