<template>
  <div class="header">
    <div class="c-logo">
      <a href="/pc/talentPool
">
        <el-image :src="require('@/assets/images/school_logo.jpg')" fit="scale-down"></el-image>
      </a>
    </div>
    <div class="c-main">
      <div class="m-home">
        <router-link to="/pc/home">首页</router-link>
      </div>
      <ul class="top-nav">
        <li class="nav-item">
          <el-dropdown>
            <a href="#">
              <span>{{ integral.user_nickname }}</span>
              <span v-if="integral.user_avatar">
                <el-image :src="integral.user_avatar" fit="fill"></el-image>
              </span>
              <i class="el-icon-s-custom" v-else></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="loginOut()">
                  <i class="fa fa-hand-o-right"></i>
                  退出登录
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    getCompanyAmountApi,
  } from '@/api/account'
  export default {
    name: "Header",
    data() {
      return {
        // image: localStorage.getItem('user_avatar'),
        // franchiseName: localStorage.getItem("user_nickname"),
        integral: "",
      }
    },
    computed: {
      
    },
    created() {
      this.rechargeDataInit()
    },
    methods: {
      //页面数据初始化
      async rechargeDataInit() {
        this.loading = true
        const {
          data: res
        } = await getCompanyAmountApi()
        if (res.result.length != 0) {
          this.integral = res.result
        }
        this.loading = false
      },
      loginOut() {
        this.$store.dispatch("clearToken"),
          this.$store.commit('saveloginStatus', '')
        //清除token
        localStorage.removeItem("school_token")
        localStorage.removeItem("schoolLoginStatus")
        //刷新页面
        this.$router.go()
      }
    },
  }

</script>

<style lang="less" scoped>
  a:hover {
    color: #e1251b;
  }

  .el-dropdown-menu__item:hover {
    background: #e1251b;
    color: #fff;
  }

  .header {
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    position: relative;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    border-bottom: 1px solid #e4e7ed;

    .c-logo {
      width: 200px;
      height: 100%;
      background: #fff;

      .el-image {
        width: 100%;
        height: 100%;
      }
    }

    .c-main {
      width: 1000px;
      height: 60px;
      display: flex;
      justify-content: space-between;
    }

    .m-home {
      padding: 0px 20px;
    }

    .top-nav {
      .nav-item {
        height: 60px;
        line-height: 60px;
        padding: 0px 10px;

        .el-image {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          vertical-align: middle;
        }

        i {
          margin-left: 10px;
          font-size: 18px;
        }
      }

      .nav-txt {
        margin: 0px 20px;
        font-size: 14px;
      }
    }
  }

</style>
